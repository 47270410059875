header {
  background-color: #ebebeb !important; }
  header .navbar {
    height: 60px;
    padding: 0px; }
    header .navbar button {
      border: 0px;
      padding: 0px !important; }
      header .navbar button:hover, header .navbar button:focus {
        outline: none !important;
        box-shadow: none !important; }

body {
  height: 100vh; }

.wrapper {
  height: 100%;
  margin: 0 auto; }

header {
  height: 110px;
  width: 100%; }

.content {
  min-height: calc(100% - 220px);
  margin: 0 auto; }

footer {
  height: 110px;
  width: 100%; }

.fdb-block .fdb-touch {
  border-top: solid 0.3125rem #2E6839; }

.language-list {
  display: flex; }
  .language-list .selected img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%); }
  .language-list .language-item {
    width: 30px;
    margin-left: 7px; }
    .language-list .language-item:hover img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%); }
  .language-list img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: filter 250ms ease-in-out; }
